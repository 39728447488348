<template>
  <div>

    <div class="columns is-centered" v-if="$session._tuto_first_time_visit">
      <div class="column is-4-desktop is-7-tablet is-12-mobile">
        <div
          class="notification is-link is-light has-text-centered"
          style="padding-top: 9px;padding-bottom: 9px;margin-top: 4px;margin-bottom: 6px;font-family: cabin_sketch_regular;"
        >
          <button class="delete" @click="nonMerci"></button>

          {{ $t('sketchy.tutoriel_notif.premiere_visite') }}

          <br/>
          <div class="has-text-centered" style="margin-top: 10px;">
            <button
              class="button is-primary is-small"
              style="margin-right: 15px;font-family: cabin_sketch_regular;font-size: 13px;"
              @click="ouvrirTuto"
            >{{ $t('sketchy.tutoriel_notif.bouton_oui') }}</button>

            <button
              class="button is-warning is-small"
              style="font-family: cabin_sketch_regular;font-size: 13px;"
              @click="nonMerci"
            >{{ $t('sketchy.tutoriel_notif.bouton_non') }}</button>
          </div>
        </div>
      </div>
    </div>

    <div class="columns is-centered" v-if="rappelTutoVisible">
      <div class="column is-4-desktop is-7-tablet is-12-mobile">
        <div
          class="notification is-success is-light has-text-centered"
          style="padding-top: 9px;padding-bottom: 9px;margin-top: 4px;margin-bottom: 6px;font-family: cabin_sketch_regular;"
        >
          <div class="autoclosebar" :style="{width: largeurAutoclose + '%'}"></div>
          {{ $t('sketchy.tutoriel_notif.rappel') }}
        </div>
      </div>
    </div>

  </div>
</template>

<style lang="scss" scoped>
.autoclosebar {
  height: 4px;

  position: absolute;

  top: 0;
  left: 0;

  background-color: forestgreen;

  border-radius: 6px;

  width: 100%;
  transition: width 100ms linear;
}
</style>

<script>
export default {
  name: 'NotificationTuto',

  data() {
    return {
      tutoOuvertViaBouton: false,

      rappelTutoVisible: false,

      largeurAutoclose: 100,

      curTimeout: null,
    };
  },

  created() {
    this.$bus.$on('tuto_closed', this.tutoClosed);
  },

  beforeDestroy() {
    this.$bus.$off('tuto_closed', this.tutoClosed);
    if (this.curTimeout !== null) {
      clearTimeout(this.curTimeout);
      this.curTimeout = null;
    }
  },

  methods: {
    ouvrirTuto() {
      this.tutoOuvertViaBouton = true;
      this.$bus.$emit('open_tuto');
    },

    tutoClosed() {
      if (this.tutoOuvertViaBouton) {
        this.$session._tuto_first_time_visit = false;
        this.afficherRapelTuto();
        this.tutoOuvertViaBouton = false;
      }
    },

    nonMerci() {
      this.$session._tuto_first_time_visit = false;
      this.afficherRapelTuto();

      this._matomo_trackGoal(this.MTM_GOAL_TUTO_DISMISS);
    },

    afficherRapelTuto() {
      this.largeurAutoclose = 100;
      this.rappelTutoVisible = true;

      this.decrementRappelTuto();
    },

    decrementRappelTuto() {
      this.curTimeout = null;
      this.largeurAutoclose -= 1;

      if (this.largeurAutoclose > 0) {
        this.curTimeout = setTimeout(this.decrementRappelTuto, 80);
      } else {
        this.rappelTutoVisible = false;
      }
    },
  },
}
</script>
